import {useSignedUrlQuery} from '#/hooks/query/signed-url.tsx';
import useDownloadBlob from '#/hooks/use-download-blob.tsx';
import {isProtectedRegex} from '#/hooks/use-is-protected-url.tsx';
import Alert from '#/library/alert/Alert.tsx';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export type ProtectedAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const ProtectedAnchor = ({className, ...props}: ProtectedAnchorProps) => {
  const {t} = useTranslation();
  const [isProtectedUrl, setIsProtectedUrl] = useState(false);
  const signedUrlQuery = useSignedUrlQuery(props.href, isProtectedUrl);
  const signedUrl = isProtectedUrl ? signedUrlQuery.data?.url : props.href;
  const downloadBlob = useDownloadBlob();

  const handleDownloadFile = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (!signedUrl) return;

      const downloadName = e.currentTarget.innerText;
      const response = await fetch(signedUrl);

      downloadBlob(await response.blob(), downloadName);
    },
    [downloadBlob, signedUrl],
  );

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      if (isProtectedRegex.test(props.href ?? '')) setIsProtectedUrl(true);
    }, 250);
    return () => clearTimeout(delayInputTimeoutId);
  }, [props.href]);

  return (
    <span>
      {signedUrlQuery.isError ? (
        <Alert variant='warning'>{t('conversation.fetching-file-failure', {url: props.href})}</Alert>
      ) : !isProtectedUrl ? (
        <a {...props} target='_blank' rel='noreferrer'>
          {props.children}
        </a>
      ) : (
        isProtectedUrl && (
          <a onClick={handleDownloadFile} {...props}>
            {props.children}
          </a>
        )
      )}
    </span>
  );
};

export default memo(ProtectedAnchor);
